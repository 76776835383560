<template>
  <div>
    

  <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-secondary">
          <i class="bi bi-arrow-clockwise"></i> Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button @click="$router.push({
            name: 'expenses-create',
            query:{employee_reference:employee.reference}
          })" 
        class="btn btn-secondary">
          <i class="bi bi-arrow-clockwise"></i> Nouvelle Dépense
        </button>
      </div>

    </div>

   <div class="row">
      <div class="col-3 my-3">
        <label for=""> Matricule </label>
        <div>
          {{ employee.code }}
        </div>
      </div>
      <div class="col-3 my-3">
        <label for="">Nom</label>
        <div>
          {{employee.lastName}}
        </div>
      </div>
      <div class="col-3 my-3">
        <label for="">Prénom</label>
        <div>
          {{ employee.firstName }}
        </div>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(employee)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      // employee: {},
    };
  },
  computed: {
    ...mapGetters("employee",{
      employee:'getEmployee'
    })
  },
  methods: {
    
  },
  beforeMount() {
    this.$store.dispatch("employee/show", this.$route.params.reference);
  },
};
</script>
